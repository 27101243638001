import { getContext } from './context';
import { dayjs } from './dayjs';
import { trackEvent } from '../services/analytics';

export const redirect = (path) => {
  window.location.href = path;
};

export const redirectToCheckout = async (plan) => {
  const { invite } = getContext();

  const data = await postCheckoutSession(plan, invite);
  trackEvent('Checkout started (client)', {
    order_id: user.uid + '_' + dayjs.utc().unix(),
    products: [{ name: plan }],
    referral_code: invite,
  });
  redirect(data.url);
};
