import Alpine from 'alpinejs';
import { experimentHandler } from './datas/experiment';
import { authHandler } from './datas/auth';
import { authStore } from './stores/auth';
import { cancelSubscriptionHandler } from './datas/cancelSubscription';
import { planOptionsFormHandler } from './datas/planOptionsForm';
import wizard from '@glhd/alpine-wizard';
import { quizHandler } from './datas/quiz';
import { disabled } from './directives/disabled';
import { modelform } from './directives/modelform';
import { carouselHandler } from './datas/carousel';
import { initMouseflow } from './services/mouseflow';
import { waitlistHandler } from './datas/waitlist';
import { checkoutHandler } from './datas/checkout';
import { spotHandler } from './datas/spot';

/** @type {'production' | 'staging' | 'dev'} */
export const environment = process.env.NODE_ENV;

const docReady = (fn) => {
  if (
    document.readyState === 'complete' ||
    document.readyState === 'interactive'
  ) {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

window.marchon = true;

//datas
Alpine.data('experiment', experimentHandler);
Alpine.data('protected', authHandler);
Alpine.data('cancelSubscription', cancelSubscriptionHandler);
Alpine.data('planOptionForm', planOptionsFormHandler);
Alpine.data('quiz', quizHandler);
Alpine.data('carousel', carouselHandler);
Alpine.data('waitlist', waitlistHandler);
Alpine.data('checkout', checkoutHandler);
Alpine.data('spot', spotHandler);

//stores
Alpine.store('auth', authStore);

// Directives
Alpine.directive('modelform', modelform);
Alpine.directive('disabled', disabled);

//plugins
Alpine.plugin(wizard);

window.Alpine = Alpine;
Alpine.start();
docReady(() => {
  window.addEventListener('auth:ready', () => {
    console.log('auth:ready');
  });

  // we need to wait for consent to load the heatmaps in UK
  window.addEventListener('CookiebotOnLoad', (e) => {
    if (!window.Cookiebot.regulations.gdprApplies) {
      return initMouseflow();
    }

    if (window.Cookiebot.consented) {
      return initMouseflow();
    }
  });
});
