import { paths } from '../config';
import { client } from '../services/api';
import { redirect } from '../utils/redirect';

/**
 * Creates a radio form for selecting price slugs
 * Set x-model = option, on radios
 * Set x-data on outer wrapper
 * Set x-bind=form on the form element
 * Set x-bind=button on the submit button
 */

export const planOptionsFormHandler = (defaultOption) => ({
  option: defaultOption,
  subscriptionId: null,
  swap: {
    isLoading: false,
    isError: false,
  },

  init() {
    const defaultOption = document.querySelector(`input[checked]`);
    const defaultCheckedValue = defaultOption.getAttribute('value');
    this.option = defaultCheckedValue;
    window.addEventListener('cancel:subId', ({ detail }) => {
      this.subscriptionId = detail.id;
    });
  },

  form: {
    async ['@submit.prevent']() {
      try {
        this.swap.isLoading = true;
        this.swap.isError = false;

        await client.post(
          `user/current/subscriptions/${this.subscriptionId}/change-plan`,
          {
            json: {
              slug: this.option,
            },
          }
        );

        redirect(paths.account);
        this.swap.isLoading = false;
      } catch (e) {
        this.swap.isLoading = false;
        this.swap.isError = true;
      }
    },
  },

  swapError: {
    ['x-show']() {
      return this.swap.isError;
    },
    ['x-text']() {
      return this.swapError
        ? 'There was an error. Return to your account screen and try again.'
        : '';
    },
  },
});
