import { redirectToCheckout } from '../utils/redirect';

export const checkoutHandler = (plan) => ({
  isLoading: false,
  isError: false,
  plan,

  async redirectToCheckout() {
    try {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await redirectToCheckout(this.plan);
      this.isLoading = false;
    } catch (e) {
      this.isError = true;
      this.isLoading = false;
    }
  },
});
