import { dayjs } from './dayjs';

export const getItemWithExpiry = (key) => {
  const hasItem = localStorage.getItem(key);
  if (!hasItem) {
    return null;
  }

  const item = JSON.parse(hasItem);
  const now = dayjs.utc();
  const expiry = dayjs.utc(item.expiry);

  if (now.isAfter(expiry)) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const setItemWithExpiry = (key, value, expirySeconds) => {
  const item = {
    value,
    expiry: dayjs.utc().add(expirySeconds, 'seconds').valueOf(),
  };
  localStorage.setItem(key, JSON.stringify(item));
};
