import { identify, trackEvent } from '../services/analytics';

/**
 * Used for waitlist forms.
 * Set x-data = waitlist('waitlist name') in parent element
 * set x-bind = form on the form html element
 */

export const waitlistHandler = (list) => ({
  email: '',
  isReady: false,
  list: list || 'any',
  terms: false,
  isSuccess: false,

  init() {
    // setup the form
    const form = document.querySelector('form');
    form.removeAttribute('action');
    form.removeAttribute('method');
    form.classList.remove('w-form');
    //bind inputs
    const emailInput = form.querySelector('input[type=email]');
    const termsInput = form.querySelector('input[name=terms]');
    emailInput.setAttribute('x-model', 'email');
    termsInput.setAttribute('x-model', 'terms');
    window.addEventListener('auth:ready', ({ detail }) => {
      this.isReady = true;
      if (detail?.user?.email) {
        this.email = detail.user.email;
        return;
      }

      this.email = '';
    });
  },

  join(e) {
    e?.preventDefault();

    if (!this.email) {
      return;
    }

    identify({
      email: this.email,
      [`waitlist_${this.list}`]: true,
    });
    trackEvent('Waitlist signup', {
      email: this.email,
      waitlist: this.list,
    });
    this.isSuccess = true;
  },
});
