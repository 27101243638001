import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../services/firebase';
import { dispatchEvent } from '../utils/dispatchEvent';
import { paths } from '../config';

export const authStore = {
  user: null,
  authReady: false,
  init() {
    onAuthStateChanged(auth, async (user) => {
      dispatchEvent('auth:ready', {
        user,
      });

      this.authReady = true;
      if (!user) {
        this.user = null;
      }
      this.user = user;
    });
  },

  async signOut(next = paths.login) {
    try {
      await signOut(auth);
      this.user = null;
      this.isAuthenticated = null;
      localStorage.clear('anonId');

      // if skip is false, don't redirect
      if (!next) {
        return;
      }
      redirect(next);
    } catch (e) {
      console.log('err logging out', e);
    }
  },
};
