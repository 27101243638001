export const initMouseflow = () => {
  window._mfq = window._mfq || [];
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.defer = true;
  script.src =
    '//cdn.mouseflow.com/projects/76f74ccb-fe97-4764-b10f-1c5815954ce3.js';

  document.head.appendChild(script);
};
