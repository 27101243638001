import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

export const getParam = (param) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
};

export const setJsonCookie = (key, jsonData, expirationDays) => {
  Cookies.set(key, JSON.stringify(jsonData), { expires: expirationDays });
};

export const getJsonCookie = (key) => {
  const jsonString = Cookies.get(key);
  return jsonString ? JSON.parse(jsonString) : {};
};

const getContextFromUrl = () => {
  const context = [
    'utm_source',
    'utm_campaign',
    'utm_term',
    'utm_medium',
    'utm_content',
    'fbp',
    'fbclid',
    'fbc',
    'gclid',
    'invite',
  ].reduce((state, str) => {
    const param = getParam(str);
    const key =
      str === 'utm_campaign'
        ? 'name'
        : str.includes('utm_')
        ? str.split('_')[1]
        : str;

    if (!param) return state;

    return { ...state, [key]: param };
  }, {});

  return context;
};

export const setContext = () => {
  const context = getContext();
  setJsonCookie('attr_context', context, 7);
};

export const getContext = () => {
  const urlContext = getContextFromUrl();
  const storedContext = getJsonCookie('attr_context');

  return { ...storedContext, ...urlContext };
};

export const getCampaignContext = () => {
  const { fbc, fbclid, fbp, gclid, invite, ...rest } = getContext();
  return rest;
};

/**
 *  Sets an anonymous ID in local storage
 */
const setAnonId = (id) => {
  const ID = id || uuid();
  localStorage.setItem('anonId', ID);
};

/**
 *  Get the anon ID or set a new one
 */
export const getAnonymousId = () => {
  const currentId = localStorage.getItem('anonId');
  const newId = uuid();
  if (!currentId) {
    setAnonId(newId);
    return newId;
  }
  return currentId;
};
