/**
 * @param {string} name
 * @param {object} detail
 */

export const dispatchEvent = (name, detail) => {
  window.dispatchEvent(
    new CustomEvent(name, {
      detail,
      bubbles: true,
      // Allows events to pass the shadow DOM barrier.
      composed: true,
      cancelable: true,
    })
  );
};
