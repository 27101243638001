import Embla from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';

export const carouselHandler = (
  opts = { dragFree: true, containScroll: 'trimSnaps' },
  plugins = { autoPlay: false }
) => ({
  embla: null,
  hasNext: false,
  hasPrevious: false,
  onNext: () => {
    if (!this.next) {
      return;
    }
    this.embla.scrollNext();
  },
  onPrev: () => {
    if (!this.prev) {
      return;
    }
    this.embla.scrollPrev();
  },

  init() {
    const pluginSettings = plugins.autoPlay
      ? [Autoplay({ delay: 3000 })]
      : undefined;
    this.embla = Embla(this.$refs.carousel, opts, pluginSettings);

    this.embla.on('select', () => {
      this.hasNext = this.embla.canScrollNext();
      this.hasPrev = this.embla.canScrollPrev();
    });
    this.embla.on('init', () => {
      this.hasNext = this.embla.canScrollNext();
      this.hasPrev = this.embla.canScrollPrev();
    });
  },

  next: {
    ['@click.prevent']() {
      return this.scrollNext();
    },
    ['x-disabled']() {
      return !this.hasNext;
    },
  },
  prev: {
    ['@click.prevent']() {
      return this.scrollPrev();
    },
    ['x-disabled']() {
      return !this.hasPrev;
    },
  },
});
