import { paths } from '../config';
import { getParam } from '../utils/context';
import { redirect, redirectToCheckout } from '../utils/redirect';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../services/firebase';

const handleNextAfterAuth = () => {
  const next = getParam('next');
  const nextPlan = getParam('plan') || 'perform';

  if (!next) {
    return redirect(paths.account);
  }

  if (next.startsWith('/')) {
    return redirect(next);
  }

  switch (next) {
    case 'offer':
      redirect('/join/all-access-offer');
      break;
    case 'subscribe':
      redirect('/pricing/plan');
      break;
    case 'join':
      redirect('/join/all-access-trial');
      break;
    case 'checkout':
      redirectToCheckout(nextPlan);
      break;
    default:
      redirect(paths.account);
  }
};

/**
 *
 * @param {'auth'|'unauth'} [type]
 */
export const authHandler = (type) => ({
  user: null,
  isAuthenticated: false,

  init() {
    onAuthStateChanged(auth, async (user) => {
      if (!user) {
        this.isAuthenticated = false;
        this.user = null;

        // Redirect if a private page and not logged in
        if (type === 'auth') {
          window.location.href = `/login?next=${encodeURIComponent(
            window.location.pathname
          )}`;
        }
        return;
      }

      this.user = user;
      this.isAuthenticated = true;

      // Redirect if you should only see if not logged in. e.g. login page
      if (type === 'unauth') {
        handleNextAfterAuth();
      }
    });
  },
});
