import cookies from 'js-cookie';
import { trackEvent, identify } from '../services/analytics';
import { getJsonCookie, setJsonCookie } from '../utils/context';

export const experimentHandler = (experiment, variants) => ({
  experiment,
  variants: variants.replaceAll(' ', '').split(','),
  variant: null,

  init() {
    this.getExperiment();
  },

  async getExperiment() {
    const storedExperiments = getJsonCookie('experiments');

    const existingVariant = storedExperiments[this.experiment];

    if (existingVariant && variants.includes(existingVariant)) {
      this.variant = existingVariant;
      return;
    }

    const weight = Math.floor(Math.random() * this.variants.length);

    if (!this.variant) {
      this.variant = this.variants[weight];
    }

    setTimeout(() => {
      trackEvent('Experiment started', {
        name: this.experiment,
        variant: this.variant,
      });

      identify({
        [`experiment_${this.experiment}`]: this.variant,
      });
    }, 500);

    setJsonCookie(
      'experiments',
      { ...storedExperiments, [this.experiment]: this.variant },
      14
    );
  },
});
