export const environment = process.env.NODE_ENV;

export const paths = {
  signup: '/sign-up',
  login: '/login',
  logoutRedirect: '/',
  loginRedirect: '/account',
  account: '/account',
  faq: '/faq',
};

const CONFIGS = {
  dev: {
    environment: 'dev',
    baseApiUrl: 'http://localhost:5001/v1',
    firebaseConfig: {
      apiKey: 'AIzaSyCsLEUsqxMWCR7WYK0KjmsppFO-Ddj8PBc',
      authDomain: 'marchon-staging.firebaseapp.com',
      projectId: 'marchon-staging',
      storageBucket: 'marchon-staging.appspot.com',
      messagingSenderId: '313303001444',
      appId: '1:313303001444:web:d5d7fcd340818345ceb271',
    },
  },
  staging: {
    environment: 'staging',
    baseApiUrl: 'https://marchon-staging.herokuapp.com/v1',
    firebaseConfig: {
      apiKey: 'AIzaSyCsLEUsqxMWCR7WYK0KjmsppFO-Ddj8PBc',
      authDomain: 'marchon-staging.firebaseapp.com',
      projectId: 'marchon-staging',
      storageBucket: 'marchon-staging.appspot.com',
      messagingSenderId: '313303001444',
      appId: '1:313303001444:web:d5d7fcd340818345ceb271',
    },
  },
  production: {
    environment: 'production',
    baseApiUrl: 'https://marchon-prod.herokuapp.com/v1',
    firebaseConfig: {
      apiKey: 'AIzaSyB72YELHNuwoP8tEA-BHnvYgvJD5Ol2AJA',
      authDomain: 'marchon-prod.firebaseapp.com',
      projectId: 'marchon-prod',
      storageBucket: 'marchon-prod.appspot.com',
      messagingSenderId: '925722461772',
      appId: '1:925722461772:web:0228c8d9d1dec378fcd6e9',
    },
  },
};

export const getConfig = () => {
  if (Object.keys(CONFIGS).includes(environment)) {
    return CONFIGS[environment];
  }
  return CONFIGS.dev;
};

export const config = getConfig();
