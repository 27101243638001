import { getParam } from '../utils/context';
import { getItemWithExpiry, setItemWithExpiry } from '../utils/localStorage';
export const spotHandler = (param) => ({
  spot: null,

  init() {
    const paramToGet = param || 'spot';
    const spot = getParam(paramToGet);

    if (spot) {
      this.spot = spot;
      setItemWithExpiry(paramToGet, spot, 604800);
      return;
    }

    const storedSpot = getItemWithExpiry(paramToGet);
    console.log(storedSpot);
    if (storedSpot) {
      this.spot = storedSpot;
      return;
    }
  },
});
