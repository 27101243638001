/**
 * x-disabled = "expression to evaluate"
 * A handy helper to add disabled attribute and class to elements in webflow
 * Useful as webflow doesnt easily allow styling [disabled] and you need to add a class
 */

export const disabled = (el, { expression }, { effect, evaluate }) => {
  effect(() => {
    el.setAttribute('disabled', evaluate(expression));
    evaluate(expression)
      ? el.classList.add('disabled')
      : el.classList.remove('disabled');
  });
};
