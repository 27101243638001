/**
 * Onboard quiz helpers
 * manages state for the whole form and other elements
 * note: x-wizard:step is set on elements in webflow directly for speed
 */

import { paths } from '../config';
import { identify, trackEvent } from '../services/analytics';
import { redirect } from '../utils/redirect';

export const quizHandler = (next) => ({
  redirectOnFinish: `${paths.signup}?next=${encodeURIComponent(
    '/join/all-access-trial'
  )}`,
  started: true,
  fitnessGoal: [],
  gender: null,
  competitions: null,
  nutritionGoal: null,
  exerciseTypes: [],
  daysWorkingOut: null,
  score: {
    gain: 0,
    train: 0,
    perform: 0,
    womens: 0,
    compete: 0,
    hyrox: 0,
  },
  init() {
    if (next) {
      this.redirectOnFinish = `${paths.signup}?next=${encodeURIComponent(
        next
      )}`;
    }
  },

  async onSelect() {
    const current = this.$wizard.current();
    const totalSteps = this.$wizard.progress().total;
    const isFinalStep = this.$wizard.isLast();

    if (this.$wizard.cannotGoForward() && !isFinalStep) {
      return;
    }

    const question = current.el.querySelector('h1').innerText;
    const form = current.el.querySelector('form');
    const selectedOptions = form ? form.getAttribute('x-modelform') : null;
    const currentAnswer = selectedOptions ? this[selectedOptions] : null;

    this.addScores(current, currentAnswer);

    trackEvent('Onboarding step completed', {
      step: this.$wizard.progress().current,
      question,
      selectedOptions: currentAnswer || 'NO_OPTIONS',
      pagePath: window.location.pathname,
      wizard_name: 'june24',
    });

    if (currentAnswer) {
      identify({
        [`quiz_${selectedOptions}`]: currentAnswer,
      });
    }

    if (isFinalStep) {
      try {
        await trackEvent('Onboarding wizard completed', {
          wizard_name: 'june24',
          totalSteps,
          pagePath: window.location.pathname,
        });
      } catch (e) {}

      redirect(this.redirectOnFinish);
      return;
    }

    this.$wizard.canGoForward() && this.$wizard.forward();
  },

  addScores(currentEl, currentAnswer) {
    const elsWithScore = currentEl.el.querySelectorAll('[data-score]');

    if (elsWithScore.length < 1) {
      return;
    }
    // program scoring
    if (currentAnswer) {
      const answers =
        typeof currentAnswer === 'string' ? [currentAnswer] : currentAnswer;
      answers.forEach((a) => {
        const selected = currentEl.el.querySelector(`input[value="${a}"]`);

        const score = selected.getAttribute('data-score');
        if (score) {
          const formatted = score.split(',').map((v) => v.trim());
          formatted.forEach((v) => {
            if (!Object.keys(this.score).includes(v)) {
              return;
            }
            this.score[v]++;
          });
        }
      });
    }
  },

  showProgram(program) {
    const filteredScores = Object.entries(this.score)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 3)
      .map(([name]) => name);

    return filteredScores.includes(program);
  },

  next: {
    ['x-text']() {
      return this.$wizard.isLast() ? 'Get your program' : 'Continue';
    },
    ['@click.prevent']() {
      this.onSelect();
    },
    ['x-disabled']() {
      if (this.$wizard.isLast()) {
        return this.$wizard.isNotComplete();
      }
      return this.$wizard.cannotGoForward();
    },
  },

  nutritionText: {
    ['x-text']() {
      const texts = {
        lose_weight: '44% of members want to get lean.',
        maintain: '68% of members are maintaining weight.',
        muscle_gain: '52% of members are making lean gains.',
        high_volume: '24% of members are fueling for performance.',
      };

      return texts[this.nutritionGoal];
    },
  },
  trainText: {
    ['x-text']() {
      const texts = {
        muscle: '63% of members are looking to build muscle.',
        lean: '45% of members are looking to lose body fat.',
        fitness: '68% of members are looking to improve their fitness.',
        competition: '32% of members are training for a competition.',
      };

      return texts[this.fitnessGoal];
    },
  },
  summaryText1: {
    ['x-text']() {
      const texts = {
        muscle: 'Build muscle and strength',
        lean: 'Shred fat and look better',
        fitness: 'Get fitter and stronger',
        competition: 'Get competition-ready',
      };

      return texts[this.fitnessGoal];
    },
  },
  summaryText2: {
    ['x-text']() {
      const texts = {
        lose_weight: 'while getting leaner',
        maintain: 'while maintaining weight',
        muscle_gain: 'while building lean muscle',
        high_volume: 'while fueling for performance',
      };

      return texts[this.nutritionGoal];
    },
  },
});
