import { paths } from '../config';
import { getParam } from '../utils/context';
import { redirect } from '../utils/redirect';
import { client } from '../services/api';
import { dayjs } from '../utils/dayjs';
import { dispatchEvent } from '../utils/dispatchEvent';
import { trackEvent } from '../services/analytics';
/**
 *
 * @param {'auth'|'unauth'} [type]
 */
export const cancelSubscriptionHandler = () => ({
  subscription: null,
  subscriptionId: null,
  isInTrial: false,
  trialDaysLeft: 0,
  isLoading: true,
  isError: null,
  cancel: {
    isLoading: false,
    isError: false,
  },

  init() {
    const subId = getParam('sub');
    this.subscriptionId = subId;
    if (!subId) {
      redirect(paths.account);
    }
    window.addEventListener('auth:ready', () => {
      this.getSubscription();
    });

    this.$watch('isLoading', (val) => {
      dispatchEvent('cancel:subId', { id: this.subscriptionId });
    });
  },

  async getSubscription() {
    try {
      this.isLoading = true;
      this.error = false;
      const data = await client.get('user/current/subscriptions/list').json();

      // find the right sub
      this.subscription = data.subscriptions.find(
        ({ id }) => id === this.subscriptionId
      );

      // If the Id doesn't exist, redirect
      if (!this.subscription || !!this.subscription.cancelAtDate) {
        redirect(paths.account);
      }
      //re-init the sub values
      this.setSubValues();

      this.isLoading = false;
    } catch (e) {
      this.isError = true;
      console.log(e);
    }
  },

  setSubValues() {
    if (this.subscription?.trialEndDate) {
      this.isInTrial = this.subscription.status === 'trialing';

      this.trialDaysLeft = dayjs
        .utc(this.subscription.trialEndDate)
        .diff(dayjs.utc(), 'day');
    }
  },
  // can swap to no commitment or annual
  canSwap() {
    if (
      !this.subscription?.discount &&
      this.isInTrial &&
      this.subscription?.minCommitmentMonths < 4 &&
      this.subscription?.minCommitmentMonths
    ) {
      return true;
    }
    return false;
  },

  swapPlans: {
    ['x-if']() {
      const swaps = this.canSwap();
      if (swaps) {
        trackEvent('Change plans viewed', {
          subscription_status: this.subscription?.status,
          subscription_period_end: this.subscription?.minCommitmentEndDate,
          subscription_start_date: this.subscription?.startDate,
          subscription_display_name: this.subscription?.displayName,
          subscription_discount_percentOff:
            this.subcription?.discount?.percentOff,
        });
        return true;
      }
      return false;
    },
  },

  currentPlan: {
    ['x-if']() {
      const swaps = this.canSwap();
      if (swaps) {
        return false;
      }
      return true;
    },
  },

  cancelButton: {
    async ['@click.prevent']() {
      try {
        if (!!this.subscription.cancelAtDate) {
          return;
        }
        this.cancel.isLoading = true;
        this.cancel.isError = false;
        const data = await client.post('subscriptions/cancel', {
          json: { id: this.subscriptionId },
        });

        redirect(paths.account);

        this.cancel.isLoading = false;
      } catch (e) {
        this.cancel.isLoading = false;
        this.cancel.isError = true;
      }
    },

    ['x-text']() {
      return this.cancel.isLoading ? 'Cancelling...' : 'Cancel membership';
    },
    [':disabled']() {
      return this.cancel.isLoading ? true : false;
    },
  },
  cancelError: {
    ['x-show']() {
      return this.cancel.isError;
    },
    ['x-text']() {
      return this.cancel.isError
        ? 'There was an error cancelling your subscription. Go back to your account to try again or email us support@marchon.co.uk.'
        : '';
    },
  },
  cancelInfo: {
    ['x-text']() {
      if (this.subscription?.discount?.percentOff) {
        return `You will lose your ${this.subscription?.discount?.percentOff}% discount after your subscription ends.`;
      }

      if (this.subscription?.discount?.amountOff) {
        return `You will lose your £${
          this.subscription?.discount?.amountOff / 100
        } discount after your subscription ends.`;
      }

      return this.subscription?.smallPrint;
    },
  },

  // plan card bindings
  statusText: {
    ['x-text']() {
      return this.subcription?.cancelAtDate
        ? 'canceled'
        : this.subcription?.status;
    },
    [':class']() {
      return this.subcription?.cancelAtDate && 'canceled';
    },
  },
  startDateText: {
    ['x-text']() {
      return dayjs.utc(this.subscription?.startDate).format('D MMM YYYY');
    },
  },
  discountText: {
    ['x-text']() {
      if (this.subscription?.discount?.percentOff) {
        return `${this.subscription?.discount?.percentOff}% off`;
      }
      if (this.subscription?.discount?.amountOff) {
        return `£${this.subscription?.discount?.amountOff} off`;
      }
      return '';
    },
  },
  discount: {
    ['x-show']() {
      return !!this.subscription?.discount;
    },
  },
});
