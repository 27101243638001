import ky from 'ky';
import { auth } from './firebase';
import { config } from '../config';

export const client = ky.create({
  prefixUrl: config.baseApiUrl,
  hooks: {
    beforeRequest: [
      async (request) => {
        try {
          const token = await auth?.currentUser?.getIdToken();
          if (token) {
            request.headers.set('authorization', `Bearer ${token}`);
          }
        } catch (e) {
          return;
        }
      },
    ],
  },
});

export const postCheckoutSession = ({ slug, referral_code }) => {
  client.post(`subscriptions/checkout/session/${slug}`, {
    JSON: { referral_code: referral_code || undefined },
  });
};
